import React from 'react';
import './_twoImages.scss'
import iphoneShare from '../../../../assets/images/iphoneShare.jpeg'
import iphoneClick from '../../../../assets/images/iphoneClick.png'

export default function TwoImages() {

    return(
        <div className={'twoImages'}>

            <div className={'imagePoint'} style={{backgroundImage: `url(${iphoneClick})`}}>

                <p className={'text textImagePoint'} style={{color: '#CEC1BE'}}><span style={{color: '#FFF'}}>Access</span> all your project’s information in seconds from your phone, enabling your clients and stakeholders to get the project’s information seamlessly when needed.</p>
            </div>

            <div className={'imagePoint'} style={{backgroundImage: `url(${iphoneShare})`}}>
                <p className={'text textImagePoint'} style={{color: '#A79B99'}}><span style={{color: '#000'}}>Share and collaborate</span> with your clients and stakeholders instantly, enabling clear decision making, reducing project delays significantly. With OPUS, make sure everyone is instantly notified at every step of the way.</p>

            </div>
        </div>
    )
}
