import React, {useEffect, useState} from "react";
import './_littleComponents.scss'
import Modal from 'react-modal/lib/components/Modal';
import iconIphone from '../../../../assets/icons/iconIphone.svg'
import emptyIphones from '../../../../assets/images/emptyIphones.png'
import opusScreen from '../../../../assets/images/opusScreen.png'
import crossIcon from '../../../../assets/images/crossIcon.png'
import appStore from '../../../../assets/images/appStore.png'
import playStore from '../../../../assets/images/playStore.png'
import appStoreRond from '../../../../assets/icons/appStoreRond.png'
import PlayStoreRond from '../../../../assets/icons/PlayStoreRond.png'



export function ModalQrCode({modal, setModal}) {

    return(
        <Modal
            isOpen={modal}
            className={'modalQrCode'}
            overlayClassName="OverlayModal"
            onRequestClose={() => {
                setModal(false);
            }}
            shouldCloseOnOverlayClick={true}
            closeTimeoutMS={300}>
            <img
                src={crossIcon}
                alt="crossIcon"
                className={'crossIcon'}
                onClick={() => {
                    setModal(false);
                }}
            />

            <p className={'titleModal'}>Scan the QR Code</p>

            <p className={'titleModalPhone'}>Download OPUS for Free</p>

            <div className={'containerQr'}>
                <img src={appStore} alt=""/>
                <img src={playStore} alt=""/>
            </div>

            <button className={'widgetLink'} onClick={() =>  window.open('https://apps.apple.com/fr/app/opus-architecture-design/id1591807716?l=en-GB', '_blank')}>
                <img src={appStoreRond} alt="" className={'storeRond'}/>
                <div>
                    <p className={'titleStore'}>Apple App Store</p>
                </div>
            </button>

            <button className={'widgetLink'} onClick={() =>  window.open('https://play.google.com/store/apps/details?id=com.opusoft&pcampaignid=web_share', '_blank')}>
                <img src={PlayStoreRond} alt="" className={'storeRond'}/>
                <div>
                    <p className={'titleStore'}>Android Play Store</p>
                </div>
            </button>
        </Modal>


    )
}


export function GreyButton({title, icon, qrCode, url}) {

    const [modalQrCode, setModalQrCode] = useState(false)

    return(
        <>
            <button
                className={'greyButton'}
                onClick={() => {
                    if (qrCode) {
                        setModalQrCode(true)
                    } else {
                        window.open(url, '_blank');
                    }
                }}>
                {title}
                {icon && <img src={icon} alt="" className={'icon'}/>}
            </button>

            <ModalQrCode modal={modalQrCode} setModal={setModalQrCode}/>
        </>

    )
}

export function LargeTitle({
                               text1,
                               text2,
                               text3,
                               color1,
                               color2,
                               color3,
                               right,
                               lineThrough,
                               button,
                               complementaryText,
                               paralax,
                               noparalax,
                               styleComplement
                           }) {


    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);


    return(
        <div className={`containerLargeTitle ${right ? 'right' : ''}`}>
            <div>
                {complementaryText && <p className={'complementaryText'}>{complementaryText}</p>}
                {button && <GreyButton title={button} icon={iconIphone} qrCode={true}/>}
            </div>

            <h2 className={`largeTitle ${right ? 'right' : ''} ${styleComplement || ''}`} style={{transform: !noparalax && `translate(0%, ${-paralax + scrollY * 0.15}px)`}}>
                <span style={{color: color1}}>{text1}</span> <br/>
                <span style={{color: color2, textDecoration: lineThrough && 'line-through'}}>{text2}</span> <br/>
                <span style={{color: color3}}>{text3}</span>
            </h2>
        </div>

    )
}


export function EmptyIphones() {

    return(
        <div className={'emptyIphones'}>
            <div style={{transform: 'rotate(30deg)'}}>
                <img src={opusScreen} alt="" className={'opusScreen'}
                     style={{transform: `rotateX(0deg) rotateY(0deg)`}}/>

            </div>

            <img src={emptyIphones} alt="" className={'emptyIphonesMockup'}/>
        </div>
    )
}
