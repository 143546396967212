import React from 'react';
import './_timeline.scss'
import timeline from "../../../../assets/images/timeline.png";

export default function Timeline() {

    return(
        <div className={'timeline'}>

            <div className={'containerTimeline'}>

                <div className={'textPart'}>
                    <h3 className={'title'}>
                        From design to execution,
                        turn months into weeks,
                        with OPUS
                    </h3>

                    <p className={'text'}><span style={{color: '#000'}}>Solve the #1 and #2 cause of delay</span> in your projects with OPUS. We help you
                        streamline your projects from A to Z, empowering you to turn weeks into days, starting
                        today.</p>
                </div>
                <div className={'containerTimelineImg'}>
                    <img src={timeline} alt="" className={'timelineImg'}/>

                </div>

            </div>


        </div>
    )
}
