import React, {useEffect, useState} from 'react'
import './_landingPart.scss'
import handPhone from '../../../../assets/images/handPhone.png'
import blueLightBackground from '../../../../assets/images/blueLightBackground.png'

export default function LandingPart() {


    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const [backgroundOpacity, setBackgroundOpacity] = useState(0);
    const [textOpacity, setTextOpacity] = useState(0);

    useEffect(() => {
            setTimeout(() => {
                setBackgroundOpacity(1);
                setTextOpacity(1);

            }, 500);
    });

    return(
        <div className={'landingPart'}>
            <img src={blueLightBackground} alt="" className={'blueLightBackground'} style={{opacity: backgroundOpacity}}/>

            <h1 className={'landingTextPart'} style={{transform: `translate(0%, ${-50 + scrollY * 0.35}px)`}}>
                 <span style={{color: 'rgba(0,0,0,0.5)'}}>Redefine</span>
                 <span style={{color: 'rgba(0,0,0,0.65)'}}>Project</span>
                <span style={{color: '#3478F6'}}>Monitoring.</span>

            </h1>
            <img src={handPhone} alt="" className={'handIphone'}/>

        </div>
    )
}

