import React from 'react';
import './_twoiPhones.scss'
import twoIphones from '../../../../assets/images/twoIphones.png'

export default function TwoiPhones() {

    return(
        <div className={'twoiPhones'}>

            <div className={'textPart'}>
                <h3 className={'titleTwoIphones'}>Never hear, “can you
                    send me the project’s
                    info?” ever again.</h3>

                <p className={'subtitleTwoIphones'}><span style={{color: '#000'}}>Solve the #1 cause of delay in your projects,</span> useless communication.  Streamline your project’s processes, reduce the back-and-forth with stakeholders to save time and reduce delays.</p>
            </div>

            <div className={'containeriPhones'}>
                <img src={twoIphones} alt="" className={'twoIphonesImg'}/>

            </div>
        </div>
    )
}
