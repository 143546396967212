import React, {useEffect, useRef, useState} from 'react';
import './_landingPage.scss'
import WebsiteHeader from "./components/Website Header/WebsiteHeader";
import LandingPart from "./components/Landing Part/LandingPart";
import Introduction from "./components/Introduction/Introduction";
import Spacer from "../../components/Spacer";
import TwoImages from "./components/Two Images/TwoImages";
import TimeLost from "./components/Time Lost/TimeLost";
import TwoPoints from "./components/Two Points/TwoPoints";
import WebsiteFooter from "./components/Website Footer/WebsiteFooter";
import ImageCTA from "./components/Image CTA/ImageCTA";
import {EmptyIphones, LargeTitle} from "./components/_littleComponents/LittleComponents";
import TwoiPhones from "./components/Two iPhones/TwoiPhones";
import Timeline from "./components/Timeline/Timeline";

export default function LandingPage() {


        return(
        <div className={'landingPage'}>
            <WebsiteHeader/>

            <LandingPart/>
            <Spacer height={32}/>

            <Introduction/>

            <Spacer height={100}/>

            <LargeTitle
                right={true}
                text1={'Simply,'}
                text2={'the best way to'}
                text3={'collaborate.'}
                color1={'#717171'}
                color2={'#5B5B5B'}
                color3={'#333333'}
                paralax={200}
                styleComplement={'first'}
            />

            <Spacer height={100}/>

            <TwoImages/>

            <Spacer height={100}/>

            <LargeTitle
                lineThrough={true}
                text1={'What if,'}
                text2={'80% of project’s delays'}
                text3={'could be avoided.'}
                color1={'#474747'}
                color2={'#FF0000'}
                color3={'#3478F6'}
                paralax={420}
                styleComplement={'second'}

            />

            <Spacer height={100}/>

            <TimeLost/>

            <Spacer height={100}/>

            <LargeTitle
                right={true}
                text1={'Stop dreaming,'}
                text2={'it’s now possible'}
                text3={'with OPUS.'}
                color1={'#717171'}
                color2={'#5B5B5B'}
                color3={'#333333'}
                paralax={750}
                styleComplement={'third'}


            />

            <Spacer height={100}/>

            <TwoPoints/>

                <TwoiPhones/>
            <Spacer height={100}/>

            <LargeTitle
                text1={"We give your"}
                text2={"team back"}
                text3={"its time."}
                color1={'#717171'}
                color2={'#5B5B5B'}
                color3={'#3478F6'}
                noparalax={true}
                button={'Say Goodbye to delays'}
                styleComplement={'fourth'}
                complementaryText={"Your time is valuable, at OPUS we’re dedicated towards simplifying communication to help you save time and reduce project’s delay."}
            />

            <Spacer height={100}/>

                <Timeline/>

            <Spacer height={100}/>

            <LargeTitle
                right={true}
                text1={'Try now'}
                text2={'and save'}
                text3={'hours today.'}
                color1={'#717171'}
                color2={'#5B5B5B'}
                color3={'#3478F6'}
                noparalax={true}
                styleComplement={'fourth'}
                button={"Join now - For Free"}
                complementaryText={"Add your project, team and Stakeholders for free, and start saving hours daily instantly. Start building  without delays, starting today."}
            />
            <Spacer height={100}/>

            <ImageCTA/>

            <Spacer height={100}/>

            <WebsiteFooter/>

        </div>
    )
}
