import React from 'react';
import './_twoPoints.scss'
import opusApp from '../../../../assets/icons/opusApp.png'
import langueApp1 from '../../../../assets/icons/langueApp1.png'
import langueApp2 from '../../../../assets/icons/langueApp2.png'
import langueApp3 from '../../../../assets/icons/langueApp3.png'
import langueApp4 from '../../../../assets/icons/langueApp4.png'
import mailAppNotif from '../../../../assets/icons/mailAppNotif.png'
import whatsAppNotif from '../../../../assets/icons/whatsAppNotif.png'
import opusAppNotif from '../../../../assets/icons/opusAppNotif.png'

export default function TwoPoints() {

    return(
        <div className={'twoPoints'}>

            <div className={'itemPoint'}>
                <p className={'text textImagePoint'} style={{color: '#A79B99'}}><span style={{color: '#474747'}}>Share, access and collaborate instantly.</span> Say
                    goodbye to the 1,245 unread emails and endless whatsapp threads. On OPUS, everything is accessible
                    simply, saving your team some time and project’s some delay. </p>

                <p className={'titleSection'} style={{textDecoration: 'line-through'}}>Simply, goodbye</p>
                <div className={'appList'}>
                    <img src={mailAppNotif} alt="" className={'appIcon notif'}/>
                    <img src={whatsAppNotif} alt="" className={'appIcon notif'} style={{width: 80, marginTop: -5}}/>
                </div>
                <p className={'titleSection'}>Say Hello,</p>
                <div className={'appList'}>
                    <img src={opusAppNotif} alt="" className={'appIcon notif opusNotif'}/>
                </div>
            </div>

            <div className={'itemPoint'}>
                <p className={'text textImagePoint'} style={{color: '#A79B99'}}><span style={{color: '#474747'}}>Speak the same language</span> with
                    everyone within the project. Solve the #2 cause of delays, miscommunication. With OPUS, make sure
                    all project Stakeholders are aligned, avoiding mistakes, streamlining delays.</p>

                <p className={'titleSection'}>Go from,</p>

                <div className={'appList'}>
                    <img src={langueApp1} alt="" className={'appIcon'}/>
                    <img src={langueApp2} alt="" className={'appIcon'}/>
                    <img src={langueApp3} alt="" className={'appIcon'}/>
                    <img src={langueApp4} alt="" className={'appIcon'}/>

                </div>

                <p className={'titleSection'}>to OPUS.</p>

                <div className={'appList'}>
                    <img src={opusApp} alt="" className={'appIcon opus'}/>
                </div>
            </div>
        </div>
    )
}

