import React from  'react';
import './_introduction.scss'
import introVisuel from '../../../../assets/images/introVisuel.jpeg'

export default function Introduction() {

    return(
        <div className={'introduction'}>

            <p className={'text introductionText'}><span style={{color: '#000'}}>At OPUS,</span> we provide your clients, team and stakeholders with a the first centralised, real-time project monitoring environment. Any information about the project is easily accessible by everyone in seconds from your phone.</p>

            <img src={introVisuel} alt="" className={'introVisuel'}/>
        </div>
    )
}
