import React from "react";
import './_imageCTA.scss'
import imageCta from "../../../../assets/images/imageCta.png";
import widgetProject from "../../../../assets/images/widgetProject.png";

export default function ImageCTA() {
    return(
        <div className={'imageCTA'}  style={{backgroundImage: `url(${imageCta})`}}>
            <h3 className={'titleCta'}>Create your project now, we’re ready to
                make it happen.</h3>
            <img src={widgetProject} alt="" className={'widgetProject'}/>
        </div>
    )
}
