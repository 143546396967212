import React, {useState} from 'react';
import './_websiteFooter.scss';
import {GreyButton, ModalQrCode} from "../_littleComponents/LittleComponents";
import iconIphone from "../../../../assets/icons/iconIphone.svg";

const Footer = () => {

    const [modalQrCode, setModalQrCode] = useState(false)

    return (
        <footer className="footer">
            <div className="footer-top">
                <div className="footer-brand">
                    <h1>OPUS</h1>
                </div>
                <div className="footer-links">
                    <div className="footer-section">
                        <h3>Connect</h3>
                        <a href="https://www.linkedin.com/company/opus-uae/">LinkedIn</a>
                        <a href="mailto:contact@opus-uae.com">Email</a>
                    </div>
                    <div className="footer-section">
                        <h3>Discover</h3>
                        <a>Product</a>
                        <a>Network</a>
                    </div>
                    <div className="footer-section">
                        <h3>Legal</h3>
                        <a href="https://opusoft.notion.site/Privacy-Policy-c26e28fedd3447339adc507699735636?pvs=74">Safety & Privacy</a>
                        <a href="https://opusoft.notion.site/Terms-of-Service-1345899b434b48409953797190b23917">Terms of use</a>
                        <a href="/https://opusoft.notion.site/General-Terms-and-Conditions-of-Sale-6ab478ac1712448eac40c09f4df1fb66">Terms of Sales</a>
                    </div>
                    <div className="footer-section">
                        <h3>Software</h3>
                        <a onClick={() => setModalQrCode(true)}>Get the App</a>
                        <a onClick={() => setModalQrCode(true)}>Create an Account</a>
                        <a onClick={() => setModalQrCode(true)}>Log In</a>
                    </div>
                </div>
            </div>


            <GreyButton title={'Get the app now'} icon={iconIphone} qrCode={true}/>

            <p className={'allRightReserved'}>OPUS 2024, All rights reserved.</p>

            <ModalQrCode modal={modalQrCode} setModal={setModalQrCode}/>

        </footer>
    );
};

export default Footer;
