import React from "react";
import './_timeLost.scss'
import iphoneClick from '../../../../assets/images/iphoneClick.png'
import plus from '../../../../assets/icons/plus.svg'
import commercial from '../../../../assets/images/commercial.png'
import publicImg from '../../../../assets/images/publicImg.jpeg'
import development from '../../../../assets/images/development.png'
import hospitality from '../../../../assets/images/hospitality.png'
import residential from '../../../../assets/images/residential.png'

export default function TimeLost() {

    function ItemTimeLost({image, compensator, months, surTitle, sector, complementaryText}) {

        return(
            <div className={'itemTimeLost'}  style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), url(${image})`}}>
                <div className={'sector'}>
                    <img src={plus} alt="" className={'plus'}/>
                    {sector}
                </div>
                {complementaryText && <p className={'complementaryText'}>{complementaryText}</p>}

                <div style={{height: compensator}} className={'fixableCompensator'}/>
                <div className={'compensator'}/>
                <p className={'surTitle'}>{surTitle}</p>
                <p className={'monthIndicator'}>{months} months.</p>
            </div>
        )
    }

    return(
        <div className={'timeLost'}>
            <div style={{display: 'flex', gap: 30, flex: 1, width: '100%'}} className={'containerRow'}>
                <div style={{display: 'flex', flexDirection: 'column', flex: 1, gap: 30}}>
                    <ItemTimeLost image={commercial} compensator={250} months={2} surTitle={'Average project delay on Commercial projects'} sector={'Commercial'}/>
                    <ItemTimeLost image={hospitality} compensator={250} months={4} surTitle={'Average project delay on F&B and Hospitality projects'} sector={'Hospitality'}/>
                </div>

                <div style={{display: 'flex', flexDirection: 'column', flex: 1, gap: 30}}>
                    <ItemTimeLost image={development} compensator={400} months={7} surTitle={'Average project delay on development projects'} sector={'Development'}/>
                    <ItemTimeLost image={publicImg} compensator={100} months={8} surTitle={'Average project delay on public projects'} sector={'Public'}/>
                </div>
            </div>

            <div style={{display: 'flex'}}>
                <ItemTimeLost image={residential}  compensator={270}  months={3} surTitle={'Average project delay on private projects'} sector={'Residential'} complementaryText={'Estimation based on industry research and 1,500+ real projects data in the Middle East. '}/>
            </div>
        </div>
    )
}
