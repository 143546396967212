import React from 'react';
import './_websiteHeader.scss'
import {GreyButton} from "../_littleComponents/LittleComponents";
import iconIphone from '../../../../assets/icons/iconIphone.svg'

export default function WebsiteHeader() {
    return(
        <div className={'websiteHeader'}>
            <p className={'opus'}>OPUS</p>
            <GreyButton title={'Get the app now'} icon={iconIphone} qrCode={true} />
            <div className={'classicFlex loginPart'} style={{width: 200, justifyContent: 'flex-end'}}>
                <GreyButton title={'Log In'} url={'https://opusoft.app'} />

                <div className={'o'}>
                    O
                </div>

            </div>
        </div>
    )
}
